import { environment } from "../environments/environment"
export class AssetsPath {
    public static images = 'images/';
    public static path = 'student/assets/';
    public static token = environment.SASTokenForUnivercityLogo;
    public static brandingAssetsStudent = {
      //  logo: `${environment.assetBaseUrl}${AssetsPath.images}university-logo.png?alt=media`,
        favicon: `${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}favicon.ico?${environment.SASTokenForFavicon}`,
        favicon_ico: `${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}favicon.ico?${environment.SASTokenForFavicon}`,
        Chatbot:`${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}Chatbot.png?${environment.SASTokenForChatbotIcon}`,
        Charlotte:`${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}Charlotte.png?${environment.chatbotIconForMessage}`,
        StudentAzureBot: `${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}stdChatbot.png?${environment.SASTokenForSudentAzureBot}`,
        logo: `${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}university-logo.png?${environment.SASTokenForUnivercityLogo}`,
        logout: `${environment.assetBaseUrl}${AssetsPath.path}${AssetsPath.images}icon-logout.svg?${environment.SASTokenForLogoutButton}`,
        //logo: 'https://genaichatbotcudev.blob.core.windows.net/chatbots/student/assets/images/university-logo_cu.png?sp=r&st=2024-10-24T13:20:39Z&se=2026-04-30T21:20:39Z&sv=2022-11-02&sr=b&sig=BfTsYxqpeM211Re20UFWe5%2B7lAyV7vNOurDDEFKPggo%3D',
    };
}

(window as any).AssetsPath = AssetsPath;