declare function getValidAccessToken(): any;
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

import { AssetsPath } from 'src/static-assets/assets-path';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-azure',
  templateUrl: './azure.component.html',
  styleUrls: ['./azure.component.scss']
})
export class AzureComponent implements OnInit{


  public boxArea = "box1";
  public buttonArea = "btn-area";
  public showAcknowledge = false;
  public isDisabled = true;
  public disabledButton = false;
  public chatIconShow = false;
  public toShow = false;
  public assetsPath = AssetsPath;
  public toshowTick = false;
  public title = `Chat bots for ${environment.name} University`;
  public question1 = environment.Question1;
  public question2 = environment.Question2;
  public question3 = environment.Question3;
  public chatBotName = environment.chatbotName;
  public showDiv: boolean = true;
  isVisible: boolean = false;
  acknowledged: boolean = false;
  showFullscreen: boolean = false;
  chatbotId: string = "";
  acknowledgmentVerified: string = 'false';

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ){}

  

  public clickAcknowledgment()
{
  this.isDisabled= !this.isDisabled;
}

public readAcknowledgment()
{
  this.showAcknowledge= !this.showAcknowledge;
}
public startConversation(){
this.showAcknowledge= !this.showAcknowledge;
  this.boxArea = "box1 landing"
  this.buttonArea = "accepted_button";
  this.disabledButton = true;
  this.toshowTick = true;
  this.chatIconShow = true;
  const user_email = sessionStorage.getItem('userEmail');
  const mode = 'set';
  this.sendAcknowledgement(this.chatbotId, user_email, mode);
}
public closeButton()
{
  this.showAcknowledge = !this.showAcknowledge;
}

toggleVisibility() {
  this.isVisible = !this.isVisible;
}

fadeInFadeOut() {
  this.showDiv = !this.showDiv;
}

maximizeChat() {
    const chatPopup = document.querySelector('.hk-chatbot-popup');
    if (chatPopup.classList.contains('maximized')) {
      chatPopup.classList.remove('maximized');
  } else {
      chatPopup.classList.add('maximized');
  }
  
}

async ngOnInit() {
  //setApiUrlsForStudentStaff(environment.studentAsStaffConfig);
  this.chatbotId = sessionStorage.getItem('chatbotIdStudent');
  this.acknowledgmentVerified = sessionStorage.getItem('acknowledgementVerified');
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
  sessionStorage.setItem('access_token',oktaToken.accessToken);
  const user = await this.oktaAuth.getUser();
  sessionStorage.setItem('userEmail',user.email);
  sessionStorage.setItem('userName', user.name);
  if (this.chatbotId && this.acknowledgmentVerified !== 'true' || this.acknowledgmentVerified == null) {
    this.checkAcknowledgment();
} else if (this.acknowledgmentVerified == 'true') {
  this.showAcknowledge = false; 
  this.showFullscreen = true;
  this.boxArea = "box1 landing"
 this.buttonArea = "accepted_button";
//  this.disabledButton = true;
 this.toshowTick = true;
 this.chatIconShow = true;
}
}

async logout(){
  sessionStorage.setItem('lastRoute','/student-support-azure')
  await this.oktaAuth.signOut();
}

removeAllCookies(){
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
}

checkAcknowledgment() {
  // this.isDisabled= !this.isDisabled;
  const user_email = sessionStorage.getItem('userEmail');
  const mode = 'get';
  this.sendAcknowledgement(this.chatbotId, user_email, mode);
}

acknowledgeTerms(checked: boolean) {
  this.acknowledged = checked;
  localStorage.setItem('termsAcknowledgedStudent', checked ? 'true' : 'false');
}

public FAQs = [
  {category:"Tuition & Fees",query:"What are the scholarship options available?"},
  {category:"Tuition & Fees",query:"What are the different modes are available?"},
  {category:"Tuition & Fees",query:"When will the scholarship be available?"},
  {category:"Tuition & Fees",query:"Am I eligible for scholarship?"},
  {category:"Tuition & Fees",query:"Tuition & Fees details for Tempo learning?"}
]

public showChatFAQs=[];

applyFAQsSelection(){
  var applyFAQ = document.getElementById("applyFaq");
  applyFAQ.style.backgroundColor = "#C4C4C4";
  var selectElement = document.getElementById("categorySelect") as HTMLSelectElement;
  var selectedOption = selectElement.options[selectElement.selectedIndex].value;
  if(this.FAQs.length>0){
    this.showChatFAQs=[];
    this.FAQs.forEach((FAQ)=>{
      if(selectedOption===FAQ.category){
        this.showChatFAQs.push({'query':FAQ.query});
      }
    })
  }
 
}

async sendAcknowledgement(chatbotId: string, userEmail: string, mode): Promise<void> {
  const body = new URLSearchParams();
  body.set('user_email', userEmail);
  body.set('mode', mode);
  body.set('chatbot_id', chatbotId);
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const subscriptionKey = environment.studentAsStaffConfig.apimSubscriptionKeyForStd;
    const token = await getValidAccessToken();
    if (environment.apimConfigurationEnable) {
      headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
      headers['AuthenticationType'] = 'Application';
      headers['Authorization'] = 'Bearer ' + token;
    }
  const response = await fetch(environment.commonConfig.acknowledgementAPI, {
    method: 'POST',
    headers: headers,
    body: body,
  });

  if (response.ok) {
    const responseData = await response.json();
    let acknowledgedStatus = responseData['Acknowledgement Status'];
    if (acknowledgedStatus === 'Not Found') {
      this.showAcknowledge = false; 
      this.chatIconShow = false;
    } else if (acknowledgedStatus == 'yes'){
      this.showAcknowledge = false; 
      this.acknowledged = true;
      this.buttonArea = "accepted_button";
      this.toshowTick = true;
      this.chatIconShow = true;
    } else if (acknowledgedStatus == 'Success'){
     this.showAcknowledge = false;
     this.boxArea = "box1 landing"
     this.buttonArea = "accepted_button";
     this.disabledButton = true;
     this.toshowTick = true;
     this.chatIconShow = true;
    }
    this.showFullscreen = true;
  } else {
    console.error('API error:', response.statusText);
  }
}



}