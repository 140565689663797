import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout-callback',
    templateUrl: './logout-callback.component.html',
    styleUrls: ['./logout-callback.component.scss']
})
export class LogoutCallbackComponent implements OnInit {


    public processingText='';

    constructor(private router: Router){}

    ngOnInit() {
        const lastRoute = sessionStorage.getItem('lastRoute');
        if(lastRoute){
            this.processingText = 'Processing logout...';
        }
        this.router.navigateByUrl(lastRoute);
    }
}